import React from 'react';
import man from './../../Assests/images/investment.png'
import { Container, Row, Col } from 'react-bootstrap';
// import Logo from'./../../Assests/images/logo.png';
import './InvestorCorner.css';
import DetaiKMP from'./../../Assests/images/DETAILS_OF_KMP.pdf'
import InvsCharted from './../../Assests/images/INVESTOR_CHARTER-STOCKBROKER.pdf'
import InvsCompl from './../../Assests/images/INVESTOR_CHARTER_COMPLAINT.pdf'
import InvsProcedure from './../../Assests/images/COMPLAINT_PROCEDURE.pdf'
import InvestChartDP from './../../Assests/images/INVESTOR_CHARTER-DEPOSITORYPARTICIPANT.pdf'
import DownLoadimg from './../../Assests/images/pdf.png'


function InvestorCorner() {
    return (
     <> 
     
     <section className="download-hero-banner">
        <div className="container"><div class="row">
            <div className="col-lg-6 col-md-5 col-12">
                <h1>Investor Corner</h1>
                {/* <h5>A collective list of forms and application</h5> */}
                </div><div className="col-lg-6 col-md-7 col-12">
                    <img className="img-fluid" src={man} />
        </div>
             
        </div></div></section>
    <Container> 
        <Row>
        <Col sm={12} className='DownloadTable' >
         <ul className='w-100 d-flex mb-0 font-weight-bold'>
            <li> Sr.No.</li>
            <li> File Name </li>
            <li className='d-block m-auto'> Downloads </li>
         </ul>
         <ul className='w-100 d-flex mb-0'>
            <li> 1. </li>
            <li> Investor Charter for Stock Broker </li>
            <li className='d-block m-auto'> <a href={InvsCharted} target='_blank'> <img src={DownLoadimg} /> </a></li>
         </ul>
         <ul className='w-100 d-flex mb-0'>
            <li> 2. </li>
            <li> Investor Charter for Depository Participant </li>
            <li className='d-block m-auto'> <a href={InvestChartDP} target='_blank'> <img src={DownLoadimg} /> </a></li>
         </ul>
         <ul className='w-100 d-flex mb-0'>
            <li> 3. </li>
            <li> Investor Complaints </li>
            <li className='d-block m-auto'> <a href={InvsCompl} target='_blank'> <img src={DownLoadimg} /> </a></li>
         </ul>
         <ul className='w-100 d-flex mb-0'>
            <li> 4. </li>
            <li> Complaints Procedure </li>
            <li className='d-block m-auto'> <a href={InvsProcedure} target='_blank'> <img src={DownLoadimg} /> </a></li>
         </ul>
         <ul className='w-100 d-flex mb-0'>
            <li> 5. </li>
            <li> Details of KMP </li>
            <li className='d-block m-auto'> <a href={DetaiKMP} target='_blank'> <img src={DownLoadimg} /> </a></li>
         </ul>
        </Col>
        </Row>
    </Container>
     </>
    );
}

export default InvestorCorner;