import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './BankAccount.css';

function BankAccount() {
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} className="ContactLeadfrom contact-table bank-account-sc">
                        <div className="ContactLeadformHeading"> 
                        Details of Client Bank Account
                        </div>
                        <p>The Details of Client Bank Accounts are as follows:</p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Name of Bank </th>
                                        <th>Name of bank Account</th>
                                        <th>Bank Account Number</th>
                                        <th>IFSC CODE</th>
                                        <th>For Purpose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>HDFC BANK LIMITED</td>
                                        <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td>
                                        <td>00030340030902</td>
                                        <td>HDFC0000003</td>
                                        <td>EQUITY AND EQUITY DERIVATIVE</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>HDFC BANK LIMITED</td>
                                        <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td>
                                        <td>00030340021000</td>
                                        <td>HDFC0000003</td>
                                        <td>COMMODITY</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>HDFC BANK LIMITED</td>
                                        <td>BHAIJEE PORTFOLIO PRIVATE LIMITED</td>
                                        <td>00030340034956</td>
                                        <td>HDFC0000003</td>
                                        <td>CURRENCY</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="contact-line">
                            <p>“Investors are requested to note that Bhaijee Portfolio Private Limited is permitted to receive/pay money from/to investor through designated banks accounts only named as client bank accounts. Bhaijee Portfolio Private Limited is also required to disclose these client bank accounts to Stock Exchange. Hence, you are requested to use following client bank accounts only for the purpose of dealings in your trading account with us. The details of these client bank accounts are also displayed by Stock Exchanges on their website under “Know/ Locate your Stock Broker”.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default BankAccount;